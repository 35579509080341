import React from 'react'

import { ErrorBoundary } from 'shared-components'

import { Layout } from './components/Layout'

import { RequestConfirmation } from './screens/Confirmation/RequestConfirmation'
import { RequestAppointment } from './screens/RequestAppointment'

export const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        index: true,
        element: (
          <ErrorBoundary>
            <RequestAppointment />
          </ErrorBoundary>
        ),
      },
      {
        path: '/request-confirmation',
        element: (
          <ErrorBoundary>
            <RequestConfirmation />
          </ErrorBoundary>
        ),
      },
    ],
  },
]
